<template>
  <div>
    <a-spin :spinning="loading">
      <a-list
        :pagination="pagination"
        :locale="locale"
      >
        <a-list-item :key="index" v-for="(item, index) in data">
          <a-list-item-meta :description="item.contentStr">
            <a slot="title" @click="gotoNotice(item)">
              {{ item.title }}
            </a>
          </a-list-item-meta>
          <div class="list-content">
            <div class="list-content-item">
              <span style="width: 60px;text-align: left;">类型</span>
              <p style="width: 60px;text-align: left;">{{ item.noticeTypeName }}</p>
            </div>
            <div class="list-content-item">
              <span>发送时间</span>
              <p>{{ item.publishTime }}</p>
            </div>
          </div>
          <div slot="actions">
            <a @click="gotoNotice(item)">查看</a>
          </div>
        </a-list-item>
      </a-list>
    </a-spin>
    <div>
      <a-drawer
        :width="800"
        placement="right"
        @close="closeModel"
        :closable="true"
        :destroyOnClose="true"
        :visible="visible"
        :drawer-style="{ position: 'absolute' }"
      >
        <div style="margin-top: 20px; width: 750px;">
          <h2 style="text-align: center;">{{ noticeInfo.title }}</h2>
          <p v-html="noticeInfo.content"></p>
          <p :key="index" v-for="(item, index) in noticeInfo.imageListData">
            <img
              style="max-width: 100%"
              :src="item.url"
            />
          </p>
        </div>
        <div style="text-align:center;margin-top: 20px;">
          <a-button @click="closeModel">关闭</a-button>
        </div>
      </a-drawer>
    </div>
  </div>
</template>
<script>
import { myNotice, setIsReaded } from '@/api/notice'
const bodystyle = {
  height: '500px',
  overflow: 'hidden',
  overflowY: 'scroll'
}
export default {
  data () {
    return {
      data: [],
      loading: false,
      visible: false,
      pagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      queryParam: {
        pageNo: 1,
        pageSize: 10
      },
      noticeInfo: {},
      locale: { emptyText: '暂无消息' },
      bodystyle
    }
  },
  created () {
    this.myNoticeApi()
  },
  methods: {
    myNoticeApi () {
      const _this = this
      _this.loading = true
      myNotice(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.data = res.result.data
          _this.pagination.pageSize = res.result.pageSize
          _this.pagination.total = res.result.totalCount
          _this.pagination.current = res.result.pageNo
          console.log('pagination', _this.pagination)
        } else {
          _this.$message.error('系统错误')
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    onChange (page, pageSize) {
      this.queryParam.pageNo = page
      this.queryParam.pageSize = pageSize
      this.myNoticeApi()
    },
    showSizeChange (current, pageSize) {
      this.pagination.current = 1
      this.pagination.defaultCurrent = 1
      this.pagination.pageSize = pageSize
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.myNoticeApi()
    },
    gotoNotice (data) {
      console.log(data)
      this.noticeInfo = data
      this.setIsReadedApi(data.id)
      this.visible = true
    },
    setIsReadedApi (noticeId) {
      setIsReaded({ noticeId: noticeId }).then((res) => {
        console.log(res)
      }).catch((err) => {
        console.log(err)
      })
    },
    closeModel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
